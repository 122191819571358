//

@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.space-mono-regular {
  font-family: 'Space Mono', monospace;
  font-weight: 400;
  font-style: normal;
}

.activate-member-modal {
  .css-1s13gk4-MuiPaper-root-MuiDialog-paper {
    max-width: 444px !important;
    min-height: 500px !important;
  }
}
.manual-wallet-transactions-modal {
  .css-1s13gk4-MuiPaper-root-MuiDialog-paper {
    width: 444px !important;
  }
}

.activate-member-success-modal {
  .css-1s13gk4-MuiPaper-root-MuiDialog-paper {
    width: 444px !important;
    height: 388px !important;
  }
  .css-1ft8eua {
    width: 444px !important;
    height: 388px !important;
  }
}
.W2W-single-view-modal {
  .css-1s13gk4-MuiPaper-root-MuiDialog-paper {
    max-width: 620px !important;
    // min-height: 340px !important;
  }
}
.notification-popup {
  .css-1s13gk4-MuiPaper-root-MuiDialog-paper {
    max-width: 480px !important;
  }
  .css-1kcwl9s .MuiDialog-paper {
    max-width: 480px !important;
  }
}

.add-member-success-modal {
  .css-1s13gk4-MuiPaper-root-MuiDialog-paper {
    max-width: 444px !important;
    max-height: 388px !important;
  }
  .css-1ft8eua {
    max-width: 444px !important;
    max-height: 388px !important;
  }
}

.add-member-checkbox {
  .css-jpnrz7-MuiFormControlLabel-root {
    margin-right: 0px !important;
  }
  .css-g5gk3y {
    margin-right: 0px !important;
  }
  .css-13p0ed3-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .css-13p0ed3-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #00a76f !important;
  }
}
.new-member-modal {
  .css-1s13gk4-MuiPaper-root-MuiDialog-paper {
    max-width: 372px !important;
    max-height: 342px !important;
  }
}
.w2w-stepper {
  .css-1c34opq-MuiStepIcon-text {
    fill: white !important;
  }
  .css-1rt2s0 {
    fill: white !important;
  }
}

.withdrawal-modal {
  .css-ihz2vm-MuiInputBase-root-MuiOutlinedInput-root {
    background-color: #f5f5f5 !important;
  }
  .css-98l834 {
    background-color: #f5f5f5 !important;
  }
  .css-gcgov3-MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .css-v49clp {
    border: none !important;
  }
  .css-vb0e11-MuiFormLabel-root-MuiInputLabel-root.MuiInputLabel-shrink {
    color: #212b36 !important;
  }
  .css-1em2iej.MuiInputLabel-shrink {
    color: #212b36 !important;
  }
}
.admin-withdrawal_modal {
  .css-ihz2vm-MuiInputBase-root-MuiOutlinedInput-root {
    background-color: rgba(145, 158, 171, 0.2) !important;
  }
}

.css-12j3jev-MuiButtonBase-root-MuiListItemButton-root .sub-icon:before,
.css-1p5uxh4-MuiButtonBase-root-MuiListItemButton-root .sub-icon:before,
.css-1yspb86 .sub-icon::before,
.css-bgx3qi .sub-icon::before {
  background-color: #007867 !important;
}

.transfer-to-wallet {
  height: 15px;
}

.withdrawal-radio {
  // .css-1vus36c-MuiButtonBase-root-MuiRadio-root{
  //     padding-top: 0px !important;
  // }
  .css-1vus36c-MuiButtonBase-root-MuiRadio-root.Mui-checked,
  .css-1mk300k-MuiButtonBase-root-MuiRadio-root.Mui-checked,
  .css-1uvdz6z.Mui-checked .css-1uvsz1h.Mui-checked {
    color: #007867 !important;
  }
}

.remove-hover-color-btn:hover {
  background-color: unset !important;
}

.custom-toggle-btn {
  border-radius: 20px !important;
  border: 1px solid #d3e8d8 !important;
}

.custom-dialog {
  .css-1s13gk4-MuiPaper-root-MuiDialog-paper,
  .css-2cnwqr-MuiPaper-root-MuiDialog-paper,
  .css-1ft8eua,
  .css-wbwwru {
    max-width: max-content !important;
  }
}

.custom-dot {
  .css-eq3g4a-MuiTimelineDot-root,
  .css-7r7mc-MuiTimelineDot-root,
  .css-11xk00u,
  .css-utktuj {
    background-color: unset !important;
    padding: 0px !important;
    margin: 0px !important;
    border-style: unset !important;
  }

  .css-2h65gw-MuiTypography-root-MuiTimelineContent-root {
    padding-top: 0px !important;
  }
}

.css-1ms7hib-MuiTimelineItem-root,
.css-628fjv {
  width: 100% !important;
}

.custom-accordion {
  position: relative !important;
}

.alert-custom {
  .css-1pxa9xg-MuiAlert-message {
    padding: 0 !important;
  }

  .css-1jnbx3k-MuiAlert-icon {
    padding: 0 !important;
  }
}

.account-card {
  border-radius: 8px !important;
}

.account-card-popover {
  border-radius: 5px !important;
}

.light-green-copy--btn {
  color: #007867 !important;
}

.light-green-copy--btn.new:hover {
  background-color: #e1efea;
}

.wallet-address {
  .css-sb65zh-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0px !important;
  }

  .css-sb65zh-MuiPaper-root-MuiAccordion-root.Mui-expanded,
  .css-qm65ji.Mui-expanded {
    box-shadow: unset !important;
  }

  .css-sb65zh-MuiPaper-root-MuiAccordion-root::before {
    margin-left: 55px !important;
  }

  .css-c7muvp-MuiSvgIcon-root,
  .css-gci6mw {
    transform: rotate(90deg) !important;
  }
}

.custom-copy-button {
  color: white;
  background-color: #007867 !important;
  border-radius: 25px !important;
  padding: 8px;
  /* 1 unit of padding in px */
}

.custom-copy-button:hover {
  background-color: #0d3f38 !important;
}

@media screen and (max-width: 899px) {
  .custom-height-100 {
    height: 100%;
  }
}

@media screen and (max-width: 899px) {
  .dashboard-card-member {
    height: 411px !important;
    padding-top: 290px !important;
  }
}

@media screen and (max-width: 723px) {
  .dashboard-card-member {
    height: 380px !important;
    padding-top: 280px !important;
  }
}

@media screen and (max-width: 678px) {
  .dashboard-card-member {
    height: 337px !important;
    padding-top: 230px !important;
  }
}

@media screen and (max-width: 540px) {
  .dashboard-card-member {
    height: 290px !important;
    padding-top: 196px !important;
  }
}

@media screen and (max-width: 500px) {
  .dashboard-card-member {
    height: 256px !important;
    padding-top: 170px !important;
  }
}

@media screen and (max-width: 450px) {
  .dashboard-card-member {
    height: 232px !important;
    padding-top: 145px !important;
  }
}

@media screen and (max-width: 420px) {
  .dashboard-card-member {
    height: 222px !important;
    padding-top: 125px !important;
  }
}

.member-sort {
  .css-1dr8ui3-MuiTabs-root .MuiTabs-scrollButtons,
  .css-y1uzvk .MuiTabs-scrollButtons {
    width: 0px;
  }

  .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root {
    width: 0px;
  }

  .css-a4xj9p-MuiTabs-root .MuiTabs-scrollButtons {
    width: 0px;
  }

  .css-1jjcu1i-MuiTabs-root .MuiTabs-scrollButtons {
    width: 0px !important;
  }

  .css-egnern-MuiSvgIcon-root,
  .css-g7go18,
  .css-g7go18 {
    margin-left: 20px !important;
    margin-right: 28px !important;
    display: none !important;
    color: red !important;
  }
}

.custom-tab {
  .css-1jjcu1i-MuiTabs-root .MuiTabs-scrollButtons {
    width: 0px !important;
  }

  .css-1jjcu1i-MuiTabs-root .MuiTabs-scrollButtons {
    width: 0px !important;
  }
}

.timer-title {
  background: linear-gradient(
    92.83deg,
    #b70047 -12.44%,
    #b13f90 21.33%,
    #583fc5 63.5%,
    #eeb400 156.65%,
    #ffffff 174.34%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.chain-node-head {
  // common
  .chain-line {
    width: 110px;
  }

  .curved-line-head {
    .chain-line-ex {
      border-radius: 173px;
      height: 160px;
      position: absolute;
      transform: rotate(90deg);
      // right: 7px;
      right: -5px;
    }
  }

  // separately

  .line-invert {
    // left: 0px;
    left: -10%;
    transform: rotate(180deg);

    .curved-line-head {
      .chain-line-ex {
        // width: 90px;
        // top: -114px;
        width: 125px;
        top: -130px;
      }
    }

    .c-node-data {
      transform: rotate(180deg);
      bottom: 23px;
      left: -70px;

      // right: -12px;
      &.hover-zoom {
        &:hover {
          transform: rotate(180deg) scale(1.1);
        }
      }
    }

    .c-node-chip {
      transform: rotate(180deg);
    }
  }

  .line-straight {
    left: 10%;

    .curved-line-head {
      .chain-line-ex {
        // width: 90px;
        // top: -27px;
        width: 125px;
        top: -14px;
      }
    }

    .c-node-data {
      top: 22px;
      // left: -4px;
      left: -70px;

      &.hover-zoom {
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  // modification
  .MuiStep-horizontal {
    min-width: 200px;
    min-height: 120px;
  }

  .MuiChip-label {
    padding: 0;
  }
}

//

.website,
.mobile-web {
  .css-i26nlv-MuiButtonBase-root-MuiListItemButton-root,
  .css-18616ld,
  .css-i9gxme,
  .css-i9gxme {
    color: #e9c98b !important;
  }
}

.crud-modal {
  .css-1kt0s4l-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 0px !important;
  }
}

.club_transaction {
  .css-bq4abc-MuiInputBase-input-MuiOutlinedInput-input,
  .css-ecos7h,
  .css-1qgr2aj-MuiInputBase-input-MuiOutlinedInput-input,
  .css-le28o6 {
    padding: 8.5px 14px !important;
  }
}

.contact {
  .css-w8cakf-MuiFormLabel-root-MuiInputLabel-root {
    color: #aba291 !important;
  }
}

.table-web {
  box-shadow:
    1px 2px 2px 0 rgba(145, 158, 171, 0.2),
    -1px 2px 2px 0 rgba(145, 158, 171, 0.2),
    /* Left shadow */ 0px -2px 2px 0 rgba(145, 158, 171, 0.2),
    /* Top shadow */ 12px 10px 24px -4px rgba(145, 158, 171, 0.12) !important;
}

.contact-website {
  ::placeholder {
    color: red;
    /* Desired color for the placeholder text */
  }

  /* For Internet Explorer */
  :-ms-input-placeholder {
    color: red;
    /* Desired color for the placeholder text */
  }

  /* For Microsoft Edge */
  ::-ms-input-placeholder {
    color: red;
    /* Desired color for the placeholder text */
  }
}

.table-main tr {
  border-bottom: 1px solid #2a2f2c !important;
}

.table-main tr:last-child {
  border-bottom: unset !important;
}

.website .css-f9x6il-MuiToolbar-root,
.css-97ust4,
.css-pn2xxv,
.css-97ust4 {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

// .css-1ikgkic-MuiModal-root-MuiDrawer-root .MuiDrawer-paper,
// .css-1yc5nfc .MuiDrawer-paper {
//   background-color: black !important;
// }

.css-w52nhq-MuiButtonBase-root-MuiListItemButton-root:before,
.css-15dz01n::before {
  display: none;
}

.css-1ajpnr9-MuiFormLabel-root-MuiInputLabel-root.MuiInputLabel-shrink {
  background-color: transparent !important;
}

// .MuiTypography-body2,
// .MuiTypography-root,
// .non-select,
// .css-f9f4sa-MuiTypography-root {
//   user-select: none !important;
// }
.my-masonry-grid_column {
  .css-sb65zh-MuiPaper-root-MuiAccordion-root::before {
    // display: none !important;
  }
}

.arrow {
  .css-19vcrl0 {
    background-color: #b2a17e33 !important;
    font-size: 50px !important;
    border-radius: 50% !important;
  }

  .css-1gptlaf-MuiSvgIcon-root {
    background-color: #b2a17e33 !important;
    font-size: 50px !important;
    border-radius: 50%;
  }
}

.css-1ned7fi-MuiStack-root {
  width: 100% !important;

  .css-1jjcu1i-MuiTabs-root {
    width: 100% !important;
  }
}

.calender-view {
  .css-xt61dw-MuiStack-root > .MuiTextField-root {
    min-width: 145px !important;
  }

  .css-xt61dw-MuiStack-root {
    width: 220px !important;

    @media (max-width: 1024px) {
      width: 285px !important;
    }
  }

  .css-ghx5yn-MuiInputBase-root-MuiOutlinedInput-root {
    width: 220px !important;

    @media (max-width: 1024px) {
      width: 285px !important;
    }
  }

  .css-1nni9zg > .MuiTextField-root {
    min-width: 220px !important;
  }
}

.table-remove-scroll {
  .css-1hckdze-MuiTable-root {
    min-width: 450px !important;
  }
}

.css-y1uzvk {
  width: 100%;
}

.css-1psf72n {
  min-width: 100% !important;
}

.table-remove-scroll-club-member {
  .css-1hckdze-MuiTable-root {
    min-width: 450px !important;
  }
}

.css-1jjcu1i-MuiTabs-root {
  // width: 90% !important;
}

.css-qrq65i-MuiButtonBase-root-MuiButton-root:hover {
  color: white !important;
  background-color: black !important;
}

.css-1t1c1sm-MuiButtonBase-root-MuiButton-root {
  width: 120px !important;
}

.css-1t1c1sm-MuiButtonBase-root-MuiButton-root:hover {
  background-color: black !important;
  color: white !important;
}

.css-nen11g-MuiStack-root {
  width: 100% !important;
}

.css-j7qwjs {
  width: 100% !important;
}

.wallet-address-field {
  // .css-qh1v61-MuiInputBase-root-MuiOutlinedInput-root {
  //   border: dashed !important;
  //   outline: none !important;

  //   .css-qh1v61-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused {
  //     outline: none !important;
  //   }
  // }
  .wallet-address-field {
    css-zyfrhy-MuiInputBase-input-MuiOutlinedInput-input:focus {
      border: 1px dashed blue !important;
    }
  }
}

.css-zyfrhy-MuiInputBase-input-MuiOutlinedInput-input {
  // border: 1px dashed blue !important;
}

.belowOneMinute {
  animation: heartbeat 1s infinite;
  transform-origin: center;

  &.belowFifteenSeconds {
    animation: heartbeat 0.3s infinite;
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
}

.copy-img {
  width: 22px !important;
}

.image-contain {
  .css-3j6ntj,
  MuiBox-root.css-3j6ntj {
    object-fit: contain !important;
  }
}

.main-head-search {
  .css-1lblljk-MuiInputBase-input-MuiOutlinedInput-input {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.no-hover-effect {
  &:hover {
    color: #1a447b !important;
    background-color: #d9deff !important;
  }
}

.my-pdf-reader {
  .my-pdf-reader-iframe {
    // display: none !important;
  }
}

.MuiFormLabel-asterisk.MuiInputLabel-asterisk,
.MuiFormLabel-asterisk.MuiInputLabel-asterisk.css-1ecnysf,
.MuiFormLabel-asterisk.MuiInputLabel-asterisk.css-4pefzv-MuiFormLabel-asterisk {
  color: red !important;
}

.hover-show-container {
  .hover-show {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    .hover-show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.padding-0-container {
  padding: 0 10px !important;
}

.partner-register-container {
  // position: relative;
  padding: 0 25px;

  .partner-register {
    .register-view-container {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (max-width: 996px) {
        top: 10%;
      }
    }
  }

  // .partner-register {
  //   margin-top: -30rem;
  //   width: 600px;
  //   margin-bottom: 100px;
  //   @media screen and (max-width: 1060px) {
  //     margin-top: -24rem;
  //   }
  //   @media screen and (max-width: 996px) {
  //     margin-top: -32rem;
  //   }
  //   @media screen and (max-width: 899px) {
  //     margin-top: -43rem;
  //   }
  //   @media screen and (max-width: 768px) {
  //     margin-top: -43rem;
  //     width: 100%;
  //   }

  //   @media screen and (max-width: 375px) {
  //     margin-top: -38rem;
  //   }
  // }
}

.small-select {
  .css-w8cakf-MuiFormLabel-root-MuiInputLabel-root {
    top: -5px;
  }

  .css-698ggw {
    height: 43px !important;
  }

  .css-em49yw .MuiOutlinedInput-root {
    height: 43px !important;
  }

  .css-1cw0187 {
    height: 43px !important;
  }

  .css-3wnyxb .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    height: 43px !important;
  }

  .css-3wnyxb .MuiOutlinedInput-root {
    height: 43px !important;
  }

  .MuiAutocomplete-hasPopupIcon.css-em49yw .MuiOutlinedInput-root,
  .MuiAutocomplete-hasClearIcon.css-em49yw .MuiOutlinedInput-root {
    height: 43px !important;
  }

  .css-fq2zi1 {
    top: -5px;
  }

  .css-4odwut-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 43px;
  }

  .css-1p61z7h-MuiInputBase-root-MuiOutlinedInput-root,
  .css-1qv85oo-MuiInputBase-root-MuiOutlinedInput-root {
    height: 43px;
  }

  .MuiFormControl-root {
    .css-ssonly-MuiInputBase-root-MuiOutlinedInput-root {
      height: 43px !important;
    }
  }
}

.chart-dropdown {
  .css-12geyt-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    width: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f5f5f5;
  }
}

.color-danger {
  color: red !important;
}

.role-select-card {
  // background-color: #fff !important;
  transition: all ease-in-out 0.3s;

  &.inactive {
    &:hover {
      border-color: #dbe0e4 !important;
      transform: scale(1.03);
    }
  }
}

.step-wrapper {
  position: relative;
  min-height: 500px;
  margin: 0 5px;

  .stepper-button {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .step {
    transition: all ease-in-out 0.6s;
    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -33rem;
    margin-top: 2rem;

    &.active {
      left: 0;
    }

    &.inactive {
    }
  }
}

#responsive-dialog-title {
  overflow: hidden;
}

.partner-create-form {
  width: 500px;
}

.modal-input {
  .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 4.5px 14px !important;
  }
}

.custom-accordion {
  .css-kdwozy-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
  }
}

.css-12vka4q-MuiFormLabel-root-MuiInputLabel-root,
.css-1jqq78o-placeholder {
  font-size: 13px;
}

.placeholder-sm {
  &::placeholder {
    font-size: 12px; // Adjust the font size as needed
  }
}

textarea,
input {
  font-size: 14px !important;
}

.select2-selection__control {
  border-radius: 8px !important;
  border: 1px solid #e8ecee !important;
}

.custom-select-ui {
  label {
    top: -6px;
  }

  .custom-select-ui-child {
    height: 40px;
  }

  .css-1x5orh4-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 40px;
  }
}

// input::placeholder {
//   font-size: 12px !important;
// }

.box-shadow-unset {
  box-shadow: unset !important;
}

.partner-type-modal {
  .css-154lg22-MuiPaper-root-MuiDialog-paper {
    max-width: unset;
    min-width: 550px !important;
  }
}

.table-pd {
  .table-pd2 {
    .session-card-pd {
      width: 25px;
    }
  }
}

.session-table {
  td {
    vertical-align: top;
    padding: 8px;
  }

  tr {
    max-width: 10px;
  }
}

.cardPadding {
  .css-1j5s3cl {
    margin-bottom: 10px;
  }

  .css-48anut-MuiStack-root {
    margin-top: 15px;
  }
}

.card2 {
  .css-8145p1 {
    margin-bottom: 10px;
  }

  .css-48anut-MuiStack-root {
    margin-top: 15px;
  }
}

.card3 {
  .css-189e0nv {
    margin-bottom: 10px;
  }

  .css-1ludl2-MuiStack-root {
    margin-top: 15px;
  }
}

.single-view-taps {
  .single-view-tab {
    .css-heg063-MuiTabs-flexContainer {
      display: flex;
      flex-direction: column;
    }

    .css-6d3pg0-MuiTabs-indicator {
      display: none !important;
    }
  }

  #simple-tabpanel-0,
  #simple-tabpanel-1 {
    flex: 1;
  }

  .css-wdsdfe-MuiButtonBase-root-MuiTab-root.Mui-selected {
    border: 1px solid #009688;
    border-radius: 10px;
    color: #009688 !important;
  }

  .css-19kzrtu {
    padding: 0;
    padding-right: 4px !important;
  }
}

@media screen and (min-width: 1200px) {
  #sidebar-inActive {
    transition: all ease-in-out 0.3s;
    width: 65px;

    .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
      width: 65px;
    }

    .css-121b4uz-MuiListItemIcon-root {
      min-width: 48px;
    }

    .css-1y6sui9 {
      width: 65px !important;
    }
  }

  #sidebar-active {
    transition: all ease-in-out 0.3s;
    width: 280px;

    .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
      width: 280px;
    }
  }
}

@media screen and (max-width: 1200px) {
  #main-body-sidebar-active {
    padding-left: 30px;
    padding-right: 30px;
  }
}

#main-body-sidebar-inActive {
  .css-1oqqzyl-MuiContainer-root {
    @media (min-width: 1200px) {
      max-width: none;
    }
  }
}

.my-new-select .css-13cymwt-control {
  border-radius: 6px;
  min-height: 40px !important;
}

.my-new-select .css-1jqq78o-placeholder {
  color: #212b36;
}

.session-update-form .css-1ifcsjq-MuiFormLabel-root-MuiInputLabel-root {
  z-index: -1 !important;
}

.my-new-select .select2-selection__menu.css-1nmdiq5-menu {
  z-index: 99;
  position: absolute;
}

.next-button.css-1umjv0b-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: #fff !important;
}

.next-button.css-fkxtkf.Mui-disabled {
  color: #fff !important;
}

.div-chip {
  .div-delete {
    color: #cc3c1b !important;
    opacity: 1 !important;
    // &:hover{
    //   opacity: 1 !important;
    //   color: #CC3C1B !important;
    // }
  }
}

.react-player-video {
  iframe {
    border-radius: 12px;

    @media screen and (max-width: 2560px) {
      width: 1000px;
      height: 550px;
    }

    @media screen and (max-width: 1440px) {
      width: 720px;
      height: 450px;
    }

    @media screen and (max-width: 1024px) {
      width: 650px;
      height: 400px;
    }

    @media screen and (max-width: 768px) {
      width: 500px;
      height: 300px;
    }
  }
}

// .btn-expand:hover{
//   background-color: white;
//   color: currentColor;
// }
.terms-privacy {
  text-decoration: underline;
  color: black;
  cursor: pointer;
}

.red-color-star {
  color: red;
}

.item-padding {
  padding-left: 0px !important;
}

.content-custom {
  font-size: 12px !important;
}

.currently {
  font-size: 17px;
}

.refer {
  font-size: 14px !important;
}

.netcard .netCard1 .css-1vypkk4-MuiPaper-root-MuiCard-root {
  margin-left: 0 !important;
}

.netcard .netCard1 .css-8b9s2m-MuiPaper-root-MuiCard-root {
  margin-left: 0 !important;
}

@media screen and (max-width: 899px) {
  .netCard3 .card {
    margin-left: 0 !important;
  }

  .netcard .netCard2 .css-1vypkk4-MuiPaper-root-MuiCard-root {
    margin-left: 0 !important;
  }

  .netcard .netCard2 .css-8b9s2m-MuiPaper-root-MuiCard-root {
    margin-left: 0 !important;
  }
}

.custom-hash-field {
  .css-gcgov3-MuiOutlinedInput-notchedOutline {
    border-color: black;
  }
}

.custom-notification-modal {
  .css-1s13gk4-MuiPaper-root-MuiDialog-paper {
    position: absolute;
    top: 60px;
    right: 30px;
    border-radius: 12px;
  }
  .css-2cnwqr-MuiPaper-root-MuiDialog-paper {
    position: absolute;
    top: 60px;
    right: 30px;
    border-radius: 12px;
  }
  .css-1ft8eua {
    position: absolute;
    top: 60px;
    right: 30px;
    border-radius: 12px;
  }
}
@media screen and (max-width: 414px) {
  .custom-notification-modal {
    .css-1s13gk4-MuiPaper-root-MuiDialog-paper {
      position: absolute;
      top: 60px;
      right: 3px;
      border-radius: 12px;
    }
    .css-2cnwqr-MuiPaper-root-MuiDialog-paper {
      position: absolute;
      top: 30px;
      right: 3px !important;
      border-radius: 12px;
    }
  }
}
.ql-align-center {
  text-align: center !important;
  overflow-wrap: break-word;
}

.ql-align-right {
  text-align: right !important;
  overflow-wrap: break-word;
}

.ql-align-justify {
  text-align: justify !important;
  overflow-wrap: break-word;
}
